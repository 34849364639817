<template>
  <div class="impot-sur">
    <Banner>
      <div class="banner-content">
        <MeepIconSimulateurs class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.simulateurs") }}</span>
      </div>
    </Banner>

    <div class="impot-sur-content page-layout">
      <md-card class="meep-form md-card-small">
        <PageHeader
            :title="$t('simulateurs.impot-sur.title')"
            :has-back="true"
            @back="goBack()"
        />
        <md-card-content>
          <div class="meep-form-column meep-form-single-column">
            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.impot-sur.revenu") }}</label>
                <md-input
                    v-model.number.number="$v.form.revenu.$model"
                    name="revenu"
                />
                <span class="md-suffix">{{ $t("suffix.euro") }}</span>

                <template v-if="$v.form.revenu.$dirty">
                  <span v-show="!$v.form.revenu.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.revenu.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.impot-sur.parts") }}</label>
                <md-input v-model.number="$v.form.parts.$model" name="parts" />

                <template v-if="$v.form.parts.$dirty">
                  <span v-show="!$v.form.parts.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.parts.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.impot-sur.abattement") }}</label>
                <md-input
                    v-model.number="$v.form.abattement.$model"
                    name="abattement"
                />

                <template v-if="$v.form.abattement.$dirty">
                  <span v-show="!$v.form.abattement.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                </template>
              </md-field>
            </div>
            <div class="md-layout md-alignment-center-right middle-button">
              <md-button class="md-raised md-primary" @click="calcu(form)">
                {{ $t("simulateurs.cal-button") }}
              </md-button>
            </div>

            <div class="meep-input meep-input-big-result">
              <p class="text">{{ $t("simulateurs.impot-sur.result") }}</p>
              <p class="result">{{ $$filters.formatNumber(result) }}€</p>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import PageHeader from "@/components/PageHeader";
import { numeric, required } from "vuelidate/lib/validators";
import calculatorsModel from "@/model/calculators";

export default {
  name: "ImpotSur",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSimulateurs,
    PageHeader,
  },

  validations: {
    form: {
      revenu: { required, numeric },
      parts: { required, numeric },
      abattement: { numeric },
    },
  },

  data() {
    return {
      form: {
        revenu: null,
        parts: null,
        abattement: null,
      },
      result: 0,
      impotSurData: [],
    };
  },

  async mounted() {
    this.impotSurData = await calculatorsModel.getAllRevenu();
  },

  methods: {
    calcu({ revenu, parts, abattement }) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let amount = 0;

        revenu = abattement > 0 ? revenu - abattement : 0.9 * revenu;

        revenu = revenu / parts;

        const sortedTax = this.impotSurData.sort(
            (a, b) => a.montant - b.montant
        );

        for (let i = 0; i < sortedTax.length - 1; i++) {
          const currentTax = sortedTax[i];
          let lowCap = 0;
          let highCap = sortedTax[i].montant;
          if (i !== 0) {
            lowCap = sortedTax[i - 1].montant;
          }
          if (revenu >= currentTax.montant) {
            // Current revenue is at the higher cap, we need to find the lower cap in order to calculate the delta
            highCap = currentTax.montant;
          } else {
            highCap = revenu;
          }

          amount += (highCap - lowCap) * currentTax.coef;

          if (highCap === revenu) {
            // We finished our calculation
            break;
          }
        }

        this.result = amount * parts;
      }
    },
  },
};
</script>

<style lang="scss">
.impot-sur {
  &-content {
    &-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 25px;
    }
  }
}
</style>
